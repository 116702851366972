import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { Input } from '@ui'
import { isValue } from '@app/util'

export const Cell = ({
  cell,
  originalRow,
  editRows,
  editValue,
  allEditedRows,
  isSticky,
  isExtraRow,
  idOfExtraRow,
  stickyOffset,
  isLast,
  isNextSticky
}) => {
  const {
    rowToHTML,
    extraRowToHTML,
    id: cellId,
    onClick,
    editable,
    editComponent,
    groupBorder,
    width,
    minWidth,
    maxWidth
  } = cell.column
  const handleStickyPosition = () => {
    if (isSticky) {
      return { left: stickyOffset || 0 }
    }
  }
  const cellProps = { ...cell.getCellProps() }

  const lastSticky = isLast && isSticky
  const withShadow = !isLast && !isNextSticky && isSticky

  const editableVal = typeof editable === 'function'
    ? editable(originalRow)
    : editable

  const isValueEdited = (editValue, cellId) => {
    // value is not present at all - no need to update anything
    if (!isValue(editValue)) {
      return false
    }

    // This sounds smart but would need more tweaking to get working correctly, eg: tracking previous value
    // if (typeof editValue[cellId] === "number") {
    //   return !Number.isNaN(editValue[cellId])
    // }

    // if (typeof editValue[cellId] === "string") {
    //   return editValue[cellId].length > 0
    // }

    return isValue(editValue[cellId])
  }

  return (
    <div
      onClick={onClick}
      className={classNames(
        'cell',
        'cell_' + cellId,
        {
          sticky: isSticky,
          'last-sticky-detection': lastSticky,
          'with-shadow-detection': withShadow,
          'is-extra-row': isExtraRow,
          'last-sticky': lastSticky,
          'with-border': !!groupBorder,
          'is-edited': isValueEdited(editValue, cellId),
          'is-selection': cellId === 'selection'
        }
      )}
      {...cellProps}
      style={{
        flex: cellId === 'selection' || lastSticky ? 'initial' : '1 0 auto',
        width: width,
        minWidth: minWidth,
        maxWidth: maxWidth,
        ...handleStickyPosition()
      }}
    >
      <div className='cell-content'>
        {editableVal && !isExtraRow ? (
          editComponent ? (
            editComponent({
              onChange: (val) => editRows(val),
              value:
                editValue && typeof editValue[cellId] !== typeof undefined
                  ? editValue[cellId]
                  : cell.row.original[cellId],
              allEditedRows: allEditedRows,
              cell: cell
            })
          ) : (
            <Input
              style={Input.STYLES.UNDERLINE}
              size={Input.SIZES.LEAN}
              onChange={(val) => editRows(val)}
              value={
                editValue && typeof editValue[cellId] !== typeof undefined
                  ? editValue[cellId]
                  : cell.row.original[cellId]
              }
            />
          )) : (
          isExtraRow && (
            <>
              {extraRowToHTML && Array.isArray(extraRowToHTML(originalRow)) ? (
                <>
                  {extraRowToHTML ? extraRowToHTML(originalRow)[idOfExtraRow] : null}
                </>
              ) : (
                <>
                  {extraRowToHTML ? extraRowToHTML(originalRow) : null}
                </>
              )}
            </>
          )
        )}
        {!isExtraRow && !editableVal && (
          rowToHTML ? rowToHTML(originalRow) : cell.render('Cell')
        )}
      </div>
    </div>
  )
}

Cell.propTypes = {
  cell: PropTypes.object,
  originalRow: PropTypes.object,
  editRows: PropTypes.func,
  editValue: PropTypes.object,
  isSticky: PropTypes.bool,
  groupBorder: PropTypes.bool,
  stickyProperties: PropTypes.object,
  allEditedRows: PropTypes.array,
  showStickyLeft: PropTypes.bool,
  showStickyRight: PropTypes.bool
}
Cell.defaultProps = {
  cell: {},
  originalRow: {},
  editValue: null,
  isSticky: false,
  groupBorder: false,
  stickyProperties: null,
  allEditedRows: [],
  showStickyLeft: false,
  showStickyRight: false
}
